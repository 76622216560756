import { RequireViewContentPermission } from "../components/auth";
import { ScheduleHeader } from "../components/layouts";
import { Schedule } from "../components/mainContent";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";
import { Store } from "../helpers";

export function SchedulePage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <div>
      <div className="h-screen w-full flex bg-bg text-white overflow-hidden">
        <CustomerSidebar />
        <div className="h-screen w-full flex flex-col ">
          <RequireViewContentPermission user={loggedInUser}>
            <>
              <ScheduleHeader />
              <Schedule />
            </>
          </RequireViewContentPermission>
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}
