import { RequireManagePermission } from "../components/auth";
import { BasePageLayout } from "../components/layouts/BasePageLayout";
import { SettingsContent } from "../components/mainContent";
import { Store } from "../helpers";

export function SettingsPage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <BasePageLayout title="Settings" showButton={false}>
      <RequireManagePermission user={loggedInUser}>
        <SettingsContent />
      </RequireManagePermission>
    </BasePageLayout>
  );
}
