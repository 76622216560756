import { AppButton, ButtonType } from "../buttons";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { Store } from "../../helpers";
import { HeaderPolygon } from "../Svgs";
import { SelectedPlaylistState } from "../../atoms";
import { RequireEditContentPermission } from "../auth";

export function PlaylistDetailHeader() {
  const [playlist] = useRecoilState(SelectedPlaylistState);

  const loggedInUser = Store.getLoggedInUser();
  const navigate = useNavigate();

  const handleEditPlaylist = () => {
    navigate("/playlist/edit");
  };

  return (
    <div className="h-17 flex-none flex justify-between items-center p-5 border-b border-page-border">
      <div>
        <div className="flex justify-center items-center  gap-3 font-medium text-gray-50">
          <Link to="/playlist" className="text-gray-300">
            Playlists
          </Link>
          <HeaderPolygon className="w-3 h-2" />
          <span>{playlist?.name}</span>
        </div>
      </div>
      <div>
        <RequireEditContentPermission user={loggedInUser}>
          <AppButton
            onClick={handleEditPlaylist}
            button_type={ButtonType.Secoundary}
          >
            Edit playlist
          </AppButton>
        </RequireEditContentPermission>
      </div>
    </div>
  );
}
