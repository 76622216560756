import AssetList from "../components/mainContent/AssetList";
import AssetListHeader from "../components/layouts/AssetListHeader";
import { LogoutModal } from "../components/modal";
import { CustomerSidebar } from "../components/sidebar";
import { RequireViewContentPermission } from "../components/auth";
import { Store } from "../helpers";

export function AssetListPage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <div className="flex">
      <div className="h-screen w-full flex bg-bg text-white">
        <CustomerSidebar />
        <div className="h-screen w-full flex flex-col">
          <RequireViewContentPermission user={loggedInUser}>
            <>
              <AssetListHeader />
              <AssetList />
            </>
          </RequireViewContentPermission>
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}
