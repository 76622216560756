import { useState } from "react";
import { UpdateUserDto, UserInfoListDto, UsersClient } from "../../services";
import { GetNewInstance, Store } from "../../helpers";
import { SpinnerButton } from "../buttons";
import { useRecoilState } from "recoil";
import { SelectedUserState } from "../../atoms";
import { toast } from "react-toastify";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function UpdateUserForm({ onSuccess, onFailure }: Props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedUser] = useRecoilState<UserInfoListDto | null>(
    SelectedUserState
  );
  const [canManage, setCanManage] = useState<boolean>(
    selectedUser?.canManage ?? false
  );
  const [canEditContent, setCanEditContent] = useState<boolean>(
    selectedUser?.canEditContent ?? false
  );
  const [canViewContent, setCanViewContent] = useState<boolean>(
    selectedUser?.canViewContent ?? false
  );
  const [canUploadContent, setCanUploadContent] = useState<boolean>(
    selectedUser?.canUploadContent ?? false
  );

  const client = new UsersClient(undefined, GetNewInstance());
  const currentCustomerId = Store.getCustomerId();

  const handleSaveChanges = async () => {
    const request = new UpdateUserDto({
      email: selectedUser?.email ?? "",
      canManage,
      canEditContent,
      canViewContent,
      canUploadContent,
    });
    if (currentCustomerId) {
      setShowSpinner(true);
      selectedUser?.email &&
        client
          .updateUser(currentCustomerId, request)
          .then(() => {
            toast.success("User updated successfully");
            onSuccess();
          })
          .catch(() => {
            onFailure();
          })
          .finally(() => {
            setShowSpinner(false);
          });
    }
  };

  return (
    <>
      <div className="my-1 flex gap-4 text-white items-center pt-2">
        <label className="flex flex-col w-1/2 text-gray-100">
          <span>First name</span>
          <input
            className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
            type="text"
            value={selectedUser?.firstName}
            disabled
          />
        </label>
        <label className="flex flex-col w-1/2 text-gray-100">
          <span>Last name</span>
          <input
            className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
            type="text"
            value={selectedUser?.lastName}
            disabled
          />
        </label>
      </div>
      <div className="my-1 flex gap-4 text-white items-top">
        <label className="flex flex-col text-gray-100">
          <span>Permissions</span>
        </label>
      </div>
      <div className="my-1 flex gap-4 text-white items-top">
        <label className="flex items-center gap-2 px-1 py-1">
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage}
            onChange={(e) => setCanManage(e.target.checked)}
          />
          <span>Can manage</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage ? "text-gray-400" : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage || canEditContent}
            onChange={(e) => setCanEditContent(e.target.checked)}
            disabled={canManage}
          />
          <span>Can edit</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage || canEditContent || canUploadContent
              ? "text-gray-400"
              : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={
              canManage || canEditContent || canUploadContent || canViewContent
            }
            onChange={(e) => setCanViewContent(e.target.checked)}
            disabled={canManage || canEditContent || canUploadContent}
          />
          <span>Can view</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage ? "text-gray-400" : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage || canUploadContent}
            onChange={(e) => setCanUploadContent(e.target.checked)}
            disabled={canManage}
          />
          <span>Can upload</span>
        </label>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <SpinnerButton
          className={`py-2.5 px-4 w-full rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary text-white ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleSaveChanges}
        >
          Save Changes
        </SpinnerButton>
      </div>
    </>
  );
}
