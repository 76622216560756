import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedPlaylistState } from "../../atoms";
import { Color } from "../../constants";
import { PlaylistListDto, UserInfoListDto } from "../../services";
import { PlaylistActionButton } from "../actionButtons";
import { NameLogo, TimeDuration } from "../Misc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { Store } from "../../helpers";
import { RequireEditContentPermission } from "../auth";

type Props = {
  playlist: PlaylistListDto;
  loggedInUser: UserInfoListDto | null;
};

export function PlaylistListItem({ playlist, loggedInUser }: Props) {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [, setSelectedPlaylist] = useRecoilState(SelectedPlaylistState);

  const typedColorString = playlist.color as keyof typeof Color;
  const navigate = useNavigate();

  const currentCustomer = Store.getCurrentCustomer();

  const handlePlaylistDetailClicked = () => {
    playlist.id &&
      playlist.name &&
      setSelectedPlaylist({ id: playlist.id, name: playlist.name });
    navigate("/playlist/details");
  };

  return (
    <tr key={playlist.id} className="text-gray-100 border-b border-page-border">
      <td className="px-4 py-2  flex gap-3 flex-row items-center ">
        <NameLogo
          onClick={handlePlaylistDetailClicked}
          className="text-body_16 text-Palette-dark-1 w-14 h-14 rounded-sm cursor-pointer"
          color={Color[typedColorString]}
          name={playlist.name ?? ""}
          letterCount={2}
        />
        <div className="flex gap-1 flex-col items-start justify-center  ">
          <span
            onClick={handlePlaylistDetailClicked}
            className="justify-center text-body_16 text-gray-100 cursor-pointer"
          >
            {playlist.name}
          </span>
          <small className="justify-center text-body_14 text-gray-300">
            {playlist.assetsCount} assets
          </small>
        </div>
      </td>
      <td className="px-4 py-3 ">
        {
          <TimeDuration
            nameId={"timeduration_listplaylist" + playlist.id}
            totalSecounds={playlist.totalDurationInSecound}
          />
        }
      </td>
      <td className="px-4 py-3 ">
        {playlist.lastUpdatedAt?.toLocaleDateString()}
      </td>
      <td className="px-4 py-3 ">
        <div className=" flex flex-row justify-start gap-2 items-center h-full w-full">
          {playlist.nextUpcomingSchedule
            ? dayjs(playlist.nextUpcomingSchedule)
                .tz(currentCustomer?.timeZoneIANA)
                .format("MMMM D [at] h:mma")
                .toString()
            : "No schedule"}
        </div>
      </td>
      <td className="px-4 py-3  w-12">
        <RequireEditContentPermission user={loggedInUser}>
          <PlaylistActionButton playlist={playlist} />
        </RequireEditContentPermission>
      </td>
    </tr>
  );
}
