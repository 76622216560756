import { IconStyle } from "../components/buttons";
import * as Icon from "react-feather";
import CustomerList from "../components/mainContent/CusomerList";
import { BasePageLayout } from "../components/layouts/BasePageLayout";
import { Store } from "../helpers";
import { PermissionManager } from "../components/auth";

export function CustomerListPage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <BasePageLayout
      title="Customers"
      buttonText="Add customer"
      icon_style={IconStyle.None}
      icon={<Icon.Camera />}
      showButton={PermissionManager.isAdminRole(loggedInUser)}
    >
      <CustomerList />
    </BasePageLayout>
  );
}
