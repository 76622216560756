import { useRecoilState } from "recoil";
import {
  ModalTypeState,
  SelectedAssetPlaylistState,
  SelectedPlaylistState,
} from "../../atoms";
import { BaseModal } from "./BaseModal";
import { PlaylistsClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { ModalTypes } from "./ModalTypes";
import { Store } from "../../helpers";
import { toast } from "react-toastify";
import { SpinnerButton } from "../buttons";
import { useState } from "react";

type Props = {
  onSuccess: () => void;
};

export function DeletePlaylistAssetModal({ onSuccess }: Props) {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedPlaylist] = useRecoilState(SelectedPlaylistState);
  const [selectedAssetPlaylistId] = useRecoilState(SelectedAssetPlaylistState);
  const [showSpinner, setShowSpinner] = useState(false);

  const client = new PlaylistsClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();

  const onCancel = () => {
    setModalType(null);
  };

  const onDeleteClick = () => {
    if (selectedPlaylist) {
      setShowSpinner(true);
      client
        .deletePlaylistsAsset(
          usersCustomerId ?? "",
          selectedPlaylist.id,
          selectedAssetPlaylistId ?? ""
        )
        .then((x) => {
          toast.success("Playlist's asset deleted successfully");
          setModalType(null);
          onSuccess();
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  };

  return (
    <BaseModal
      id={ModalTypes.DeletePlaylistAssetModal}
      title="Remove asset from playlist"
      description={
        "Are you sure you want to remove the asset from " +
        selectedPlaylist?.name +
        "?"
      }
    >
      <div className="py-4 text-sm text-white">
        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
          >
            Cancel
          </button>

          <SpinnerButton
            onClick={onDeleteClick}
            className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default"
            showSpinner={showSpinner}
          >
            Delete
          </SpinnerButton>
        </div>
      </div>
    </BaseModal>
  );
}
