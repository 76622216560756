import { useRecoilState } from "recoil";
import { BaseModal } from "./BaseModal";
import { ModalTypes } from "./ModalTypes";
import { UserInfoListDto } from "../../services";
import { SelectedUserState } from "../../atoms";
import { UpdateUserForm } from "../form";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function UpdateUserModal({ onSuccess, onFailure }: Props) {
  const [selectedUser] = useRecoilState<UserInfoListDto | null>(
    SelectedUserState
  );

  return (
    <BaseModal
      id={ModalTypes.UpdateUserModal}
      title="Update user"
      description={`Update user ${selectedUser?.email}`}
    >
      <UpdateUserForm onSuccess={onSuccess} onFailure={onFailure} />
    </BaseModal>
  );
}
