import { UserInfoListDto, UserCustomerStatus } from "../../services";
import { StatusText, StatusType } from "../typography";
import { UserActionButton } from "../actionButtons";

type Props = {
  User: UserInfoListDto;
};

export function UserListItem({ User }: Props) {
  const getStatusType = () => {
    switch (User.userCustomerStatus) {
      case UserCustomerStatus.Active:
        return StatusType.Active;
      case UserCustomerStatus.Pending:
        return StatusType.Inactive;
      case UserCustomerStatus.Archived:
        return StatusType.Danger;
      default:
        return StatusType.Active;
    }
  };

  const formatPermissions = () => {
    let permissions = [];
    if (User.canManage) {
      permissions.push("Manage");
    }
    if (User.canEditContent) {
      permissions.push("Edit");
    }
    if (User.canViewContent) {
      permissions.push("View");
    }
    if (User.canUploadContent) {
      permissions.push("Upload");
    }
    return permissions.join(", ");
  };

  return (
    <tr key={User.email}>
      <td className="px-4 py-3 border-b border-page-border">
        <div className="flex items-end gap-2">{User.firstName}</div>
      </td>
      <td className="px-4 py-3 border-b border-page-border">{User.lastName}</td>
      <td className="px-4 py-3 border-b border-page-border">{User.email}</td>
      <td className="px-4 py-3 border-b border-page-border">
        {formatPermissions()}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {User.dateCreated?.toLocaleDateString()}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        <StatusText status={getStatusType()}>
          {User.userCustomerStatus ?? ""}
        </StatusText>
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        <UserActionButton user={User} />
      </td>
    </tr>
  );
}
