import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedUserState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { UsersClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { ModalTypes } from "./ModalTypes";
import { Store } from "../../helpers";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function ArchiveUserModal({ onSuccess, onFailure }: Props) {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedUser, setSelectedUser] = useRecoilState(SelectedUserState);

  const customerId = Store.getCustomerId();

  const client = new UsersClient(undefined, GetNewInstance());

  const onCancel = () => {
    setSelectedUser(null);
    setModalType(null);
  };

  const onArchiveClick = () => {
    selectedUser?.email &&
      customerId &&
      client
        .archiveUser(customerId, selectedUser?.email)
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          onFailure();
        });
  };

  return (
    <BaseModal
      id={ModalTypes.ArchiveUserModal}
      title="Archive user"
      description={
        "Are you sure you want to archive " +
        selectedUser?.firstName +
        " " +
        selectedUser?.lastName +
        "?"
      }
    >
      <div className="py-4 text-sm text-white">
        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
          >
            Cancel
          </button>
          <button
            onClick={onArchiveClick}
            className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default"
          >
            Archive
          </button>
        </div>
      </div>
    </BaseModal>
  );
}
