import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetNewInstance } from "../helpers";
import { AcceptInvitationDto, UsersClient } from "../services";
import { PasswordStrength } from "../components/auth/PasswordStrength";
import { useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";

const userJoinRequestSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  password: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&;])(?=.{8,})/,
      "Must contain min 8 characters, Atleast one uppercase, one lowercase, one number and one special case character"
    ),
  confirmationPassword: yup
    .string()
    .required("Password confirmation is required")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});

export function AcceptNewInvitation() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let code = searchParams.get("code") ?? undefined;
  let email = searchParams.get("email") ?? undefined;
  let customer = searchParams.get("customer") ?? undefined;
  let customerId = searchParams.get("customerid") ?? undefined;

  const client = new UsersClient(undefined, GetNewInstance());

  useEffect(() => {
    !(code && email) && navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AcceptInvitationDto>({
    resolver: yupResolver(userJoinRequestSchema),
  });

  const watchPassword = watch("password");

  const onSubmit = (request: AcceptInvitationDto) => {
    request.token = code;
    request.email = email;
    request.customerId = customerId;

    client.acceptInvite(request).then((response) => {
      if (response?.isSuccessful) navigate("/login");
      else
        toast.error(
          "Invitation has expired, request new invitation from your admin"
        );
    });
  };

  return (
    <div className="h-screen w-full overflow-auto bg-bg text-white">
      <div className=" h-32 flex items-center pl-16">
        <img
          className=" h-12"
          src={require("../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="Pandoras Box"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md">
          <div className="font-medium text-h3 text-gray-50">
            Accept invitation & join
          </div>
          <small className="opacity-50 text-gray-200 text-body_14">
            {customer
              ? "Join your organization on Pandoras Box."
              : "Activate your admin account for Pandoras Box."}
          </small>
          <form className="py-4 text-sm" onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4 flex gap-4">
              <label className="flex flex-col w-1/2 text-gray-100">
                <span>First name</span>
                <input
                  {...register("firstName")}
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                  type="text"
                />
                <small className="text-red-600">
                  {errors.firstName?.message}
                </small>
              </label>
              <label className="flex flex-col w-1/2 text-gray-100">
                <span>Last name</span>
                <input
                  {...register("lastName")}
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                  type="text"
                />
                <small className="text-red-600">
                  {errors.lastName?.message}
                </small>
              </label>
            </div>
            <div className="my-4">
              <label className="flex flex-col text-gray-100">
                <span>Email</span>
                <input
                  {...register("email")}
                  value={email}
                  disabled
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                  type="email"
                />
              </label>
            </div>
            {customer && (
              <div className="my-4">
                <label className="flex flex-col text-gray-100">
                  <span>Company</span>
                  <input
                    value={customer}
                    className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                    type="text"
                    disabled
                  />
                </label>
              </div>
            )}
            <div className="my-4 text-gray-100">
              <label className="flex flex-col">
                <span>New password</span>
                <input
                  {...register("password")}
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                  type="password"
                />
                <small className="text-red-600">
                  {errors.password?.message}
                </small>
              </label>
            </div>
            <div className="my-4 text-gray-100">
              <label className="flex flex-col">
                <span>Confirm password</span>
                <input
                  {...register("confirmationPassword")}
                  className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                  type="password"
                />
              </label>
              <PasswordStrength className="py-1" password={watchPassword} />
              <small className="text-red-600">
                {errors.confirmationPassword?.message}
              </small>
            </div>
            <div className="my-8">
              <button className="w-full py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary">
                Accept invitation & join
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
