import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ServerResponseOfUserInfoListDto,
  UpdateUserProfileDto,
  UsersClient,
} from "../../services";
import { SpinnerButton } from "../buttons";
import { PasswordStrength } from "../auth/PasswordStrength";
import * as yup from "yup";
import { GetNewInstance, Store } from "../../helpers";
import { useState } from "react";
import { toast } from "react-toastify";
import { UilEye } from "@iconscout/react-unicons";

const updateUserProfileDtoSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  oldPassword: yup.string(),
  newPassword: yup.string().when("oldPassword", {
    is: new RegExp("^(?=.*[a-z])(?=.*[A-Z])"),
    then: yup
      .string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&;])(?=.{8,})/,
        "Must contain min 8 characters, Atleast one uppercase, one lowercase, one number and one special case character"
      ),
  }),
  confirmationPassword: yup.string().when("oldPassword", {
    is: true,
    then: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref("newPassword")], "Passwords does not match"),
  }),
});

export default function ProfileUpdate() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<UpdateUserProfileDto>({
    resolver: yupResolver(updateUserProfileDtoSchema),
  });

  const navigate = useNavigate();
  const customerId = Store.getCustomerId();
  let loggedInUser = Store.getLoggedInUser();

  const watchPassword = watch("newPassword");
  const client = new UsersClient(undefined, GetNewInstance());

  const onCancelClick = () => {
    navigate(-1);
  };

  const onSubmit = (request: UpdateUserProfileDto) => {
    request.customerId = customerId;
    request.email = loggedInUser?.email;

    setShowSpinner(true);
    client
      .changeUserProfile(request)
      .then((response: ServerResponseOfUserInfoListDto | null) => {
        toast.success("Profile updated successfully");
        response?.data && Store.setLoggedInUser(response?.data);
        loggedInUser = Store.getLoggedInUser();
      })
      .catch((x) => {
        toast.error("Something happened please try again");
      })
      .finally(() => setShowSpinner(false));
  };

  return (
    <div className="h-full overflow-auto p-5">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="font-medium text-h3 text-gray-50">
            Profile Information
          </div>
          <small className="opacity-50 text-gray-200 text-body_14">
            Your profile information as it was provided.
          </small>
        </div>
        <div className="mt-1 md:mt-0 md:col-span-2">
          <div className="flex justify-start">
            <div className="w-full max-w-md">
              <form className={`py-1 text-sm `}>
                <div className="my-1 flex gap-4">
                  <label className="flex flex-col w-1/2 text-gray-100">
                    <span>First name</span>
                    <input
                      {...register("firstName")}
                      className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                      type="text"
                      defaultValue={loggedInUser?.firstName}
                    />
                    <small className="text-red-600">
                      {errors.firstName?.message}
                    </small>
                  </label>
                  <label className="flex flex-col w-1/2 text-gray-100">
                    <span>Last name</span>
                    <input
                      {...register("lastName")}
                      className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
                      type="text"
                      defaultValue={loggedInUser?.lastName}
                    />
                    <small className="text-red-600">
                      {errors.lastName?.message}
                    </small>
                  </label>
                </div>
                <div className="my-4">
                  <label className="flex flex-col text-gray-100">
                    <span>Email</span>
                    <input
                      className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                      type="email"
                      disabled
                      value={loggedInUser?.email}
                    />
                  </label>
                </div>
                <div className="my-4">
                  <label className="flex flex-col text-gray-100">
                    <span>Old password</span>
                    <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden">
                      <input
                        {...register("oldPassword")}
                        autoComplete="current-password"
                        className="h-full w-full pl-4 bg-transparent border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                        type={showOldPassword ? "text" : "password"}
                      />
                      <UilEye
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        className="w-5 h-5 mx-4 cursor-pointer"
                      />
                    </div>
                    <small className="text-red-600">
                      {errors.oldPassword?.message}
                    </small>
                  </label>
                </div>

                <div className="my-1 flex gap-4">
                  <label className="flex flex-col w-1/2 text-gray-100">
                    <span>New Password</span>
                    <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden">
                      <input
                        {...register("newPassword")}
                        autoComplete="current-password"
                        className="h-full w-full pl-4 bg-transparent border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                        type={showNewPassword ? "text" : "password"}
                      />
                      <UilEye
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="w-5 h-5 mx-4 cursor-pointer"
                      />
                    </div>
                    <small className="text-red-600">
                      {errors.newPassword?.message}
                    </small>
                  </label>
                  <label className="flex flex-col w-1/2 text-gray-100">
                    <span>Confirm Password</span>
                    <div className="h-11 rounded-md border border-page-border flex items-center bg-input-bg overflow-hidden">
                      <input
                        {...register("confirmPassword")}
                        autoComplete="current-password"
                        className="h-full w-full pl-4 bg-transparent border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
                        type={showConfirmPassword ? "text" : "password"}
                      />
                      <UilEye
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="w-5 h-5 mx-4 cursor-pointer"
                      />
                    </div>
                    <small className="text-red-600">
                      {errors.confirmPassword?.message}
                    </small>
                  </label>
                </div>
                <div>
                  <PasswordStrength className="py-1" password={watchPassword} />
                </div>
                <div className="flex flex-row gap-4 my-8">
                  <SpinnerButton
                    className={`w-full justify-center pt-3 pb-3 pr-5 pl-5 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
                      showSpinner ? "disabled" : ""
                    } `}
                    showSpinner={showSpinner}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Change profile
                  </SpinnerButton>
                </div>
                <div className="flex flex-row justify-center gap-4 my-8 text-body_14 text-gray-50 cursor-pointer">
                  <span onClick={onCancelClick}>Cancel</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
