import { UserInfoListDto } from "../../services";
import { PermissionManager } from "./PermissionManager";

type props = {
  user: UserInfoListDto | null;
  children: JSX.Element;
};

export function RequireAdminRole({ user, children }: props) {
  return <>{PermissionManager.isAdminRole(user) ? children : <></>}</>;
}
