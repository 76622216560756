import { IconStyle } from "../components/buttons";
import * as Icon from "react-feather";
import UserList from "../components/mainContent/UserList";
import { BasePageLayout } from "../components/layouts/BasePageLayout";
import { Store } from "../helpers";
import { PermissionManager, RequireManagePermission } from "../components/auth";

export function UserListPage() {
  const loggedInUser = Store.getLoggedInUser();

  return (
    <BasePageLayout
      title="Users"
      buttonText="Invite users"
      icon_style={IconStyle.None}
      icon={<Icon.Camera />}
      showButton={PermissionManager.canManage(loggedInUser)}
    >
      <RequireManagePermission user={loggedInUser}>
        <UserList />
      </RequireManagePermission>
    </BasePageLayout>
  );
}
