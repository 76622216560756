import { Link, useLocation } from "react-router-dom";
import {
  UilFileAlt,
  UilAirplay,
  UilCalendarAlt,
  UilUsersAlt,
  UilSetting,
} from "@iconscout/react-unicons";
import { formatSizeFromBytes, Store } from "../../helpers";
import { ProfileInfo } from "./ProfileInfo";
import { MinimizedUploadStatus } from ".";
import {
  RequireManagePermission,
  RequireEditContentPermission,
  RequireViewContentPermission,
} from "../auth";
import { AssetUploadModal } from "../modal";
import {
  ShowAssetUploadState,
  CurrentCustomerAssetSizeState,
} from "../../atoms";
import { useRecoilState } from "recoil";

export function CustomerSidebar() {
  const [, setShowAssetUpload] = useRecoilState(ShowAssetUploadState);
  const [currentCustomerAssetSize] = useRecoilState(
    CurrentCustomerAssetSizeState
  );
  const currentCustomer = Store.getCurrentCustomer();

  let location = useLocation();

  const loggedInUser = Store.getLoggedInUser();

  const getHumanReadableFileSize = () => {
    var formattedSize = formatSizeFromBytes(currentCustomerAssetSize);

    if (currentCustomer?.storageLimit) {
      formattedSize += ` of ${formatSizeFromBytes(
        currentCustomer.storageLimit * 1024 * 1024 * 1024
      )}`;
    }

    return formattedSize;
  };

  return (
    <div className="h-screen w-72 flex flex-none flex-col border-r border-page-border ">
      <div className="h-17 flex-none border-b border-page-border">
        <img
          src={require("../../assets/Pandoras_Box_Logo_RGB_negativ_SOLID 1.png")}
          alt="pandoras box logo"
          className="pt-3 pl-6 pb-3"
        />
      </div>
      <div className="h-full text-gray-300 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
        <ul className="mt-7">
          <RequireViewContentPermission user={loggedInUser}>
            <>
              <Link to="/asset">
                <li
                  className={`flex items-center gap-3 py-4 pl-5 cursor-pointer ${
                    location.pathname.includes("asset") ||
                    location.pathname === "/" ||
                    location.pathname === "/asset/details"
                      ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                      : ""
                  }`}
                >
                  <UilFileAlt size="16.67px" color="#FFFFFF" />
                  <p className="text-body_16">Assets</p>
                </li>
              </Link>
              <Link to="/playlist">
                <li
                  className={`flex items-center gap-3 py-4 pl-5 cursor-pointer ${
                    location.pathname.includes("playlist")
                      ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                      : ""
                  }`}
                >
                  <UilAirplay size="16.67px" color="#FFFFFF" />
                  <p className="text-body_16">Playlists</p>
                </li>
              </Link>
              <Link to="/schedules">
                <li
                  className={`flex items-center gap-3 py-4 pl-5 cursor-pointer ${
                    location.pathname.includes("schedule")
                      ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                      : ""
                  }`}
                >
                  <UilCalendarAlt size="16.67px" color="#FFFFFF" />
                  <p className="text-body_16">Schedules</p>
                </li>
              </Link>
            </>
          </RequireViewContentPermission>
          <RequireManagePermission user={loggedInUser}>
            <>
              <Link to="/user">
                <li
                  className={`flex items-center gap-3 py-4 pl-5 cursor-pointer ${
                    location.pathname.includes("user")
                      ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                      : ""
                  }`}
                >
                  <UilUsersAlt size="16.67px" color="#FFFFFF" />
                  <p className="text-body_16">Users</p>
                </li>
              </Link>
              <Link to="/settings">
                <li
                  className={`flex items-center gap-3 py-4 pl-5 cursor-pointer ${
                    location.pathname.includes("settings")
                      ? "bg-white bg-opacity-10 border-l-blue-500 border-l-4 text-white"
                      : ""
                  }`}
                >
                  <UilSetting size="16.67px" color="#FFFFFF" />
                  <p className="text-body_16">Settings</p>
                </li>
              </Link>
            </>
          </RequireManagePermission>
          <RequireEditContentPermission user={loggedInUser}>
            <li>
              <div className="flex text-body_14 gap-3 py-4 pl-5 ">
                Usage: {getHumanReadableFileSize()}
              </div>
            </li>
          </RequireEditContentPermission>
        </ul>
      </div>
      <MinimizedUploadStatus />
      <AssetUploadModal onSuccess={() => setShowAssetUpload(false)} />
      <ProfileInfo />
    </div>
  );
}
