import { BaseModal } from "./BaseModal";
import { AddUserForm } from "../form";
import { ModalTypes } from "./ModalTypes";
import { UserInfoListDto } from "../../services";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
  existingUsers: UserInfoListDto[];
};

export function AddUserModal({ onSuccess, onFailure, existingUsers }: Props) {
  return (
    <BaseModal
      id={ModalTypes.HeaderModal}
      title="Invite users"
      description="They will receive invitation email guiding them to complete their registration."
    >
      <AddUserForm
        onSuccess={onSuccess}
        onFailure={onFailure}
        existingUsers={existingUsers}
      />
    </BaseModal>
  );
}
