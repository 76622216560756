import { useRecoilState } from "recoil";
import { ModalTypeState, SelectedUserState } from "../../atoms";
import { BaseModal } from "./BaseModal";
import { UsersClient } from "../../services";
import { GetNewInstance } from "../../helpers/HttpCommon";
import { ModalTypes } from "./ModalTypes";
import { toast } from "react-toastify";
import { SpinnerButton } from "../buttons";
import { useState } from "react";
import { Store } from "../../helpers";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function DeleteUserModal({ onSuccess, onFailure }: Props) {
  const [, setModalType] = useRecoilState(ModalTypeState);
  const [selectedUser, setSelectedUser] = useRecoilState(SelectedUserState);
  const [showSpinner, setShowSpinner] = useState(false);

  const customerId = Store.getCustomerId();

  const client = new UsersClient(undefined, GetNewInstance());

  const onCancel = () => {
    setSelectedUser(null);
    setModalType(null);
  };

  const onDeleteClick = () => {
    if (selectedUser?.email && customerId) {
      setShowSpinner(true);
      client
        .deleteUser(customerId, selectedUser?.email)
        .then(() => {
          toast.success("User deleted successfully");
          onSuccess();
        })
        .catch(() => {
          onFailure();
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  };

  return (
    <BaseModal
      id={ModalTypes.DeleteUserModal}
      title="Delete user"
      description={
        "Are you sure you want to delete " + selectedUser?.email + "?"
      }
    >
      <div className="py-4 text-sm text-white">
        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10"
          >
            Cancel
          </button>

          <SpinnerButton
            onClick={onDeleteClick}
            className="py-2.5 px-4 rounded-md bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button-bg-destructive-default"
            showSpinner={showSpinner}
          >
            Delete
          </SpinnerButton>
        </div>
      </div>
    </BaseModal>
  );
}
