import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RequireAuth } from "../components/auth";
import {
  AcceptExistingInvitation,
  AcceptNewInvitation,
  AssetDetailPage,
  AssetListPage,
  HomePage,
  Login,
  ProfilePage,
  RequestResetPassword,
  ResetPassword,
  ResetPasswordResult,
  ResetPasswordSuccessConfirmation,
  SettingsPage,
  UserListPage,
  PlaylistListPage,
  PlaylistDetailPage,
  PlaylistEditPage,
  SchedulePage,
} from "../pages";

export function Routing() {
  return (
    <div className="m-0 font-avenir overflow-hidden">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              {" "}
              <HomePage />
            </RequireAuth>
          }
        />
        <Route
          path="asset"
          element={
            <RequireAuth>
              <AssetListPage />
            </RequireAuth>
          }
        />
        <Route
          path="asset/details"
          element={
            <RequireAuth>
              <AssetDetailPage />
            </RequireAuth>
          }
        />
        <Route path="login" element={<Login />} />
        <Route
          path="playlist"
          element={
            <RequireAuth>
              <PlaylistListPage />
            </RequireAuth>
          }
        />
        <Route
          path="playlist/details"
          element={
            <RequireAuth>
              <PlaylistDetailPage />
            </RequireAuth>
          }
        />
        <Route
          path="playlist/edit"
          element={
            <RequireAuth>
              <PlaylistEditPage />
            </RequireAuth>
          }
        />
        <Route path="user/acceptexisting" element={<AcceptExistingInvitation />} />
        <Route path="user/accept" element={<AcceptNewInvitation />} />
        <Route path="resetpassword" element={<RequestResetPassword />} />
        <Route path="resetpassword/change" element={<ResetPassword />} />
        <Route
          path="resetpassword/confirmation"
          element={<ResetPasswordSuccessConfirmation />}
        />
        <Route path="resetpassword/result" element={<ResetPasswordResult />} />
        <Route
          path="profile"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="user"
          element={
            <RequireAuth>
              <UserListPage />
            </RequireAuth>
          }
        />
        <Route
          path="settings"
          element={
            <RequireAuth>
              <SettingsPage />
            </RequireAuth>
          }
        />
        <Route
          path="schedules"
          element={
            <RequireAuth>
              <SchedulePage />
            </RequireAuth>
          }
        />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        draggable={false}
        pauseOnHover
        toastStyle={{
          background: "#171B21",
          fontFamily: "Avenir",
          color: "rgb(175 186 197)",
          width: "fit-content",
        }}
        theme={"dark"}
      />
    </div>
  );
}
