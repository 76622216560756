import { useState } from "react";
import { useRecoilState } from "recoil";
import { AssetSortState } from "../../atoms/AssetSortState";
import { AssetListPageState } from "../../atoms/AssetListPageState";
import { AssetSort } from "../../services";
import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";

export function AssetSorter() {
  const [assetSortState, setAssetSortState] = useRecoilState(AssetSortState);
  const [currentPageNumber, setCurrentPageNumber] =
    useRecoilState(AssetListPageState);
  const [sortIsShown, setSortIsShown] = useState(false);

  let timeOutId: NodeJS.Timeout;

  const onSortClick = () => {
    setSortIsShown(!sortIsShown);
  };

  const getCurrentSorting = (): string => {
    let sortingText: string = "";
    switch (assetSortState) {
      case AssetSort.Date:
        sortingText = "Recently uploaded";
        break;
      case AssetSort.AspectRatio:
        sortingText = "Aspect ratio";
        break;
      case AssetSort.MediaType:
        sortingText = "Media type";
        break;
      case AssetSort.FileName:
        sortingText = "File name";
        break;
    }
    return sortingText;
  };

  const handleOnBlur = () => {
    timeOutId = setTimeout(() => {
      setSortIsShown(false);
    }, 100);
  };

  const handleOnFocus = () => {
    clearTimeout(timeOutId);
  };

  const handleSortChanged = (sort: AssetSort) => {
    setCurrentPageNumber(1);
    setAssetSortState(sort);
  };

  return (
    <div
      className="flex"
      tabIndex={1}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    >
      <div className="relative mr-3 z-10 text-sm">
        <button
          onClick={onSortClick}
          className="h-11 gap-2 px-4 rounded-md border border-page-border flex items-center bg-white bg-opacity-5 overflow-hidden"
        >
          <span>Sort by: {getCurrentSorting()}</span>
          {sortIsShown ? <UilAngleUp /> : <UilAngleDown />}
        </button>
        <div
          className={`absolute w-full top-12 right-0 rounded-md border border-page-border bg-dropdown  ${
            sortIsShown ? "visible" : "invisible"
          }`}
        >
          <ul className=" max-h-64 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <li>
              <span
                onClick={() => handleSortChanged(AssetSort.Date)}
                className={`cursor-pointer block  px-3 py-2  ${
                  assetSortState === AssetSort.Date
                    ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                    : ""
                }`}
              >
                Recently uploaded
              </span>
            </li>
            <li>
              <span
                onClick={() => handleSortChanged(AssetSort.AspectRatio)}
                className={`cursor-pointer block px-3 py-2  ${
                  assetSortState === AssetSort.AspectRatio
                    ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                    : ""
                }`}
              >
                Aspect ratio
              </span>
            </li>
            <li>
              <span
                onClick={() => handleSortChanged(AssetSort.MediaType)}
                className={`cursor-pointer block  px-3 py-2  ${
                  assetSortState === AssetSort.MediaType
                    ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                    : ""
                }`}
              >
                Media type
              </span>
            </li>
            <li>
              <span
                onClick={() => handleSortChanged(AssetSort.FileName)}
                className={`cursor-pointer block  px-3 py-2  ${
                  assetSortState === AssetSort.FileName
                    ? " bg-white bg-opacity-10 border-l-blue-500 border-l-4"
                    : ""
                }`}
              >
                File name
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
