import { CustomerListDto, CustomerStatus, UsersClient } from "../../services";
import { StatusText, StatusType } from "../typography";
import { useNavigate } from "react-router-dom";
import { formatSizeFromBytes, GetNewInstance, Store } from "../../helpers";
import { useState } from "react";
import { baseURL } from "../../constants/ApiConstans";
import { CustomerActionButton } from "../actionButtons";
import { NameLogo } from "../Misc";
import { Color } from "../../constants";
import { useRecoilState } from "recoil";
import { CurrentCustomerAssetSizeState } from "../../atoms";
import { RequireAdminRole } from "../auth";

type Props = {
  Customer: CustomerListDto;
};

export function CustomerListItem({ Customer }: Props) {
  const [hasImage, setHasImage] = useState(true);
  const [, setCurrentCustomerAssetSize] = useRecoilState(
    CurrentCustomerAssetSizeState
  );

  const navigate = useNavigate();
  const client = new UsersClient(undefined, GetNewInstance());
  const loggedInUser = Store.getLoggedInUser();

  const handleGoToAssetClick = () => {
    if (Customer?.id) {
      client
        .getUser(Customer.id)
        .then((response) => {
          setCurrentCustomerAssetSize(Customer.totalFileSizedUsed ?? 0);
          response?.data && Store.setLoggedInUser(response?.data);
          Store.setCurrentCustomer({
            id: Customer.id,
            name: Customer.name,
            timeZoneName: Customer.timeZoneInfo?.timeZoneName ?? "",
            timeZoneIANA: Customer.timeZoneInfo?.timeZoneIANA ?? "",
            timeZoneOffset: Customer.timeZoneInfo?.timeZoneOffset ?? "",
            totalFileSizedUsed: Customer.totalFileSizedUsed ?? 0,
            logo: Customer.logo,
            storageLimit: Customer.storageLimit,
            allowedCanvasesCount: Customer.allowedCanvasesCount,
            canvasesCount: Customer.canvasesCount,
          });
        })
        .finally(() => {
          navigate("/asset");
        });
    }
  };

  const getHumanReadableFileSize = () => {
    var formattedSize = formatSizeFromBytes(Customer?.totalFileSizedUsed ?? 0);

    if (Customer?.storageLimit) {
      formattedSize += ` of ${formatSizeFromBytes(
        Customer.storageLimit * 1024 * 1024 * 1024
      )}`;
    }

    return formattedSize;
  };

  return (
    <tr key={Customer.id}>
      <td className="px-4 py-2 border-b border-page-border">
        <div
          onClick={handleGoToAssetClick}
          className="cursor-pointer flex gap-4 items-center"
        >
          <div className="flex justify-center items-center">
            {hasImage && Customer.logo ? (
              <img
                style={{ overflowClipMargin: "unset" }}
                className="h-8 w-8 object-cover rounded-full"
                src={`${baseURL}/api/customers/${Customer.id}/logo?logo=${Customer.logo}`}
                alt="logo"
                onError={() => setHasImage(false)}
              />
            ) : (
              <NameLogo
                className={`text-gray-200 min-h-[2rem] min-w-[2rem] rounded-full text-sm`}
                color={Color.Gray}
                name={Customer?.name ?? ""}
                letterCount={2}
              />
            )}
          </div>

          <p className="justify-center">{Customer.name}</p>
        </div>
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {Customer.allowedCanvasesCount?.toString()}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {Customer.status === CustomerStatus.Active && (
          <StatusText status={StatusType.Active}>
            {CustomerStatus.Active}
          </StatusText>
        )}
        {Customer.status === CustomerStatus.Archived && (
          <StatusText status={StatusType.Inactive}>
            {CustomerStatus.Archived}
          </StatusText>
        )}
      </td>
      <td className="px-4 py-3 border-b border-page-border">
        {getHumanReadableFileSize()}
      </td>
      <td className="px-4 py-3 border-b border-page-border w-12">
        <RequireAdminRole user={loggedInUser}>
          <CustomerActionButton customer={Customer} />
        </RequireAdminRole>
      </td>
    </tr>
  );
}
