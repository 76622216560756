import { UserInfoListDto } from "../../services";

const isAdminRole = (user: UserInfoListDto | null): boolean =>
  user?.isAdminRole ?? false;

const canManage = (user: UserInfoListDto | null): boolean =>
  (user?.isAdminRole ?? false) || (user?.canManage ?? false);

const canEditContent = (user: UserInfoListDto | null): boolean =>
  (user?.isAdminRole ?? false) ||
  (user?.canManage ?? false) ||
  (user?.canEditContent ?? false);

const canViewContent = (user: UserInfoListDto | null): boolean =>
  (user?.isAdminRole ?? false) ||
  (user?.canManage ?? false) ||
  (user?.canEditContent ?? false) ||
  (user?.canUploadContent ?? false) ||
  (user?.canViewContent ?? false);

const canUploadContent = (user: UserInfoListDto | null): boolean =>
  (user?.isAdminRole ?? false) ||
  (user?.canManage ?? false) ||
  (user?.canUploadContent ?? false);

export const PermissionManager = {
  isAdminRole,
  canManage,
  canEditContent,
  canViewContent,
  canUploadContent,
};
