import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateCustomerDto,
  CustomersClient,
  SelectListDto,
} from "../../services";
import { useEffect, useState } from "react";
import { GetNewInstance } from "../../helpers/HttpCommon";
import * as yup from "yup";
import { SpinnerButton } from "../buttons";
import { PBDropdown } from "../inputs";
import { toast } from "react-toastify";
import { NameLogo } from "../Misc";
import { Color } from "../../constants";

const addCustomerRequestSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    // .max(50, "Maximum length for name is 50"),
    allowedCanvasesCount: yup
      .number()
      .typeError("Allowed canvas count must be a positive number")
      .required("Allowed canvas count is required")
      .positive("Allowed canvas count must be a positive number")
      .integer("Allowed canvas count must be a positive number"),
    storageLimit: yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .typeError("Storage limit must be a positive number")
      .positive("Storage limit must be a positive number")
      .max(1000000000000, "Storage limit must be less than 1,000,000,000,000")
      .test(
        "is-decimal",
        "Storage limit must be positive number with up to 2 decimals",
        (val: any) => {
          if (val != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(val);
          }
          return true;
        }
      ),
  })
  .required();

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
};

export function AddCustomerForm({ onSuccess, onFailure }: Props) {
  const [picture, setPicture] = useState<File | null>(null);
  const [timezone, setTimezone] = useState<string | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<
    SelectListDto | undefined
  >(undefined);
  const [imgData, setImgData] = useState<string | ArrayBuffer | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showTimezoneError, setShowTimezoneError] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [allCustomerNames, setAllCustomerNames] = useState<string[]>([]);
  const [timezones, setTimezones] = useState<SelectListDto[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCustomerDto>({
    resolver: yupResolver(addCustomerRequestSchema),
  });

  useEffect(() => {
    const customerClient = new CustomersClient(undefined, GetNewInstance());

    customerClient.getAllCustomerNames().then((x) => {
      x?.data && setAllCustomerNames(x?.data.map((n) => n.toUpperCase()));
    });

    loadTimezones();
  }, []);

  const onSubmit = (request: CreateCustomerDto) => {
    if (allCustomerNames.includes(customerName.toUpperCase())) return;

    if (!timezone) {
      setShowTimezoneError(true);
      return;
    } else setShowTimezoneError(false);

    request.timeZone = new Date().getTimezoneOffset() / 60;
    request.timeZoneName = timezone ?? "";

    if (!picture) {
      const customerClient = new CustomersClient(undefined, GetNewInstance());

      setShowSpinner(true);
      customerClient
        .createCustomerWithoutLogo(request)
        .then((x) => {
          toast.success("Customer created successfully");
          onSuccess();
        })
        .finally(() => setShowSpinner(false));

      return;
    }

    const apiData = new FormData();
    request.name && apiData.append("Name", request.name);
    request.allowedCanvasesCount &&
      apiData.append(
        "AllowedCanvasesCount",
        request.allowedCanvasesCount.toString()
      );
    request.timeZone && apiData.append("TimeZone", request.timeZone.toString());
    request.timeZoneName &&
      apiData.append("TimeZoneName", request.timeZoneName.toString());
    apiData.append("Logo", picture);
    apiData.append("StorageLimit", request.storageLimit?.toString() ?? "");

    setShowSpinner(true);
    const http = GetNewInstance();
    http
      .postForm("api/customers", apiData)
      .then((x) => {
        toast.success("Customer created successfully");
        onSuccess();
      })
      .finally(() => setShowSpinner(false));
  };

  const loadTimezones = () => {
    const customerClient = new CustomersClient(undefined, GetNewInstance());

    customerClient.getTimeZones().then((x) => {
      x?.data && setTimezones(x?.data);
    });
  };

  const handleTimezoneSelected = (timezone: SelectListDto) => {
    setTimezone(timezone?.value ?? "");
    setSelectedTimezone(timezone);
  };

  const onChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.item(0)) {
      console.log("picture: ", e.target.files);
      setPicture(e.target.files?.item(0));
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <form className="py-4 text-sm text-white">
      <div className="my-4">
        <label className="flex flex-col">
          <span>Customer name</span>
          <input
            {...register("name")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            type="text"
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <small className="text-red-600">{errors.name?.message}</small>
          {allCustomerNames.includes(customerName.toUpperCase()) && (
            <small className="text-red-600">Customer already registered</small>
          )}
        </label>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Number of canvases</span>
          <input
            {...register("allowedCanvasesCount")}
            className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300"
            type="number"
            min="1"
          />
          <small className="text-red-600">
            {errors.allowedCanvasesCount?.message}
          </small>
        </label>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Storage Limit</span>
          <div className="text-sm mt-2 mb-4 font-normal text-gray-200">
            Specify how much storage space this customer is allowed to use in
            gigabytes. It is recommended to set this no higher than the
            available storage on the smallest drive used by Pandoras Box.
          </div>
          <div className="relative">
            <input
              {...register("storageLimit")}
              className="border-page-border bg-white bg-opacity-5 p-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500 disabled:text-gray-300 w-full"
              type="text"
            />
            <div className="absolute inset-y-0 right-3 flex flex-row-reverse items-center pointer-events-none z-20 w-full">
              <span className="text-gray-500">GB</span>
            </div>
          </div>
          <small className="text-red-600">{errors.storageLimit?.message}</small>
        </label>
      </div>
      <div className="my-4">
        <label className="flex flex-col">
          <span>Timezone</span>
          <div className="text-sm mt-2 mb-4 font-normal text-gray-200">
            Select the time zone where this Pandoras Box installation resides.
            This should match the time zone on the Pandoras Box manager.
          </div>
          <PBDropdown
            options={timezones}
            onOptionSelected={handleTimezoneSelected}
            selectedOption={selectedTimezone}
          />
          {showTimezoneError && (
            <small className="text-red-600">Time zone is required</small>
          )}
        </label>
      </div>
      <div className="my-4">
        <div className="flex flex-col">
          <span>Logo</span>
          <div className="flex items-center gap-2 my-2 w-40">
            {imgData === null ? (
              <NameLogo
                className={`text-gray-200 min-w-[3rem] min-h-[3rem] rounded-full text-sm`}
                color={Color.Gray}
                name={customerName}
                letterCount={2}
              />
            ) : (
              <img
                style={{ overflowClipMargin: "unset" }}
                className="w-12 h-12 rounded-full object-cover"
                src={imgData?.toString() ?? ""}
                alt=""
              />
            )}
            <label>
              <span className="text-blue-500">Upload Logo</span>
              <input
                onChange={onChangePicture}
                className="invisible w-0 h-0"
                type="file"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <button className="py-2.5 px-4 rounded-md border border-page-border bg-white bg-opacity-5 hover:bg-opacity-10">
          Cancel
        </button>
        <SpinnerButton
          className={`py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleSubmit(onSubmit)}
        >
          Add customer
        </SpinnerButton>
      </div>
    </form>
  );
}
