import { useState } from "react";
import { CreateUserDto, UserInfoListDto, UsersClient } from "../../services";
import { UilPlus } from "@iconscout/react-unicons";
import { GetNewInstance, Store } from "../../helpers";
import { Trash2 } from "react-feather";
import { SpinnerButton } from "../buttons";
import { toast } from "react-toastify";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
  existingUsers: UserInfoListDto[];
};

type AddUsers = {
  index: number;
  email: string;
  emailIsValid: boolean;
  emailIsUnique: boolean;
  canManage: boolean;
  canEditContent: boolean;
  canViewContent: boolean;
  canUploadContent: boolean;
};

const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function AddUserForm({ onSuccess, onFailure, existingUsers }: Props) {
  const [newUsers, setNewUsers] = useState<AddUsers[]>([
    {
      index: 0,
      email: "",
      emailIsValid: true,
      emailIsUnique: true,
      canManage: false,
      canEditContent: false,
      canViewContent: false,
      canUploadContent: false,
    },
  ]);
  const [showSpinner, setShowSpinner] = useState(false);

  const client = new UsersClient(undefined, GetNewInstance());
  const usersCustomerId = Store.getCustomerId();

  const isEmailUnique = (email: string) => {
    return (
      !existingUsers.some(
        (x) => x.email?.trim().toLowerCase() === email.trim().toLowerCase()
      ) &&
      newUsers.filter(
        (x) => x.email.trim().toLowerCase() === email.trim().toLowerCase()
      ).length <= 1
    );
  };

  const validateUsers = (): boolean => {
    const updatedUsers = newUsers.map((user) => ({
      ...user,
      emailIsValid: emailRegex.test(user.email),
      emailIsUnique: isEmailUnique(user.email),
    }));
    setNewUsers(updatedUsers);

    return updatedUsers.every(
      (user) => user.emailIsValid && user.emailIsUnique
    );
  };

  const handleInviteUsers = () => {
    if (!validateUsers()) {
      return;
    }
    const request: CreateUserDto[] = newUsers.map(
      (x) =>
        new CreateUserDto({
          email: x.email,
          canManage: x.canManage,
          canEditContent: x.canEditContent,
          canViewContent: x.canViewContent,
          canUploadContent: x.canUploadContent,
        })
    );
    if (usersCustomerId) {
      setShowSpinner(true);
      client
        .createUser(usersCustomerId, request)
        .then(() => {
          const message = `${
            request.length > 1 ? "Users" : "User"
          } invited successfully`;
          toast.success(message);
          onSuccess();
        })
        .catch(() => {
          onFailure();
        })
        .finally(() => setShowSpinner(false));
    }
  };

  const handleAddUser = () => {
    const newIndex = newUsers
      .slice(newUsers.length - 1, newUsers.length)
      .pop()?.index;
    newIndex !== undefined &&
      setNewUsers([
        ...newUsers,
        {
          index: newIndex + 1,
          email: "",
          emailIsValid: true,
          emailIsUnique: true,
          canManage: false,
          canEditContent: false,
          canViewContent: false,
          canUploadContent: false,
        },
      ]);
  };

  const handleRemoveUser = (index: number) => {
    if (index !== 0) {
      const updatedUsers = [...newUsers.filter((x) => x.index !== index)];
      setNewUsers(updatedUsers);
    }
  };

  const handleEmailChange = (index: number, email: string) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: email,
      index: index,
      emailIsValid: emailRegex.test(email),
      emailIsUnique: isEmailUnique(email),
      canManage: newUsers[index].canManage,
      canEditContent: newUsers[index].canEditContent,
      canViewContent: newUsers[index].canViewContent,
      canUploadContent: newUsers[index].canUploadContent,
    });
    setNewUsers(updatedUsers);
  };

  const setCanManage = (index: number, canManage: boolean) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: newUsers[index].email,
      index: index,
      emailIsValid: newUsers[index].emailIsValid,
      emailIsUnique: newUsers[index].emailIsUnique,
      canManage: canManage,
      canEditContent: newUsers[index].canEditContent,
      canViewContent: newUsers[index].canViewContent,
      canUploadContent: newUsers[index].canUploadContent,
    });
    setNewUsers(updatedUsers);
  };

  const setCanEditContent = (index: number, canEditContent: boolean) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: newUsers[index].email,
      index: index,
      emailIsValid: newUsers[index].emailIsValid,
      emailIsUnique: newUsers[index].emailIsUnique,
      canManage: newUsers[index].canManage,
      canEditContent: canEditContent,
      canViewContent: newUsers[index].canViewContent,
      canUploadContent: newUsers[index].canUploadContent,
    });
    setNewUsers(updatedUsers);
  };

  const setCanViewContent = (index: number, canViewContent: boolean) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: newUsers[index].email,
      index: index,
      emailIsValid: newUsers[index].emailIsValid,
      emailIsUnique: newUsers[index].emailIsUnique,
      canManage: newUsers[index].canManage,
      canEditContent: newUsers[index].canEditContent,
      canViewContent: canViewContent,
      canUploadContent: newUsers[index].canUploadContent,
    });
    setNewUsers(updatedUsers);
  };

  const setCanUploadContent = (index: number, canUploadContent: boolean) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1, {
      email: newUsers[index].email,
      index: index,
      emailIsValid: newUsers[index].emailIsValid,
      emailIsUnique: newUsers[index].emailIsUnique,
      canManage: newUsers[index].canManage,
      canEditContent: newUsers[index].canEditContent,
      canViewContent: newUsers[index].canViewContent,
      canUploadContent: canUploadContent,
    });
    setNewUsers(updatedUsers);
  };

  return (
    <>
      <div className="my-1 flex gap-4 text-white items-center pt-2">
        <label className="flex flex-col w-2/3">
          <span>Email</span>
        </label>
        <label className="flex flex-col items-start w-1/3">
          <span>Permissions</span>
        </label>
        <Trash2 className="cursor-pointer text-button-bg-destructive-default invisible" />
      </div>
      {newUsers.map((x, i) => (
        <NewUser
          email={x.email}
          index={x.index}
          canManage={x.canManage}
          canEditContent={x.canEditContent}
          canViewContent={x.canViewContent}
          canUploadContent={x.canUploadContent}
          handleEmailChange={handleEmailChange}
          handleRemoveUser={handleRemoveUser}
          setCanManage={setCanManage}
          setCanEditContent={setCanEditContent}
          setCanViewContent={setCanViewContent}
          setCanUploadContent={setCanUploadContent}
          emailIsValid={x.emailIsValid}
          emailIsUnique={x.emailIsUnique}
        />
      ))}
      <div
        onClick={handleAddUser}
        className="text-gray-300 flex flex-row cursor-pointer"
      >
        <UilPlus className="w-4" />
        <span>Add another user</span>
      </div>
      <div className="mt-8 flex justify-end gap-4">
        <SpinnerButton
          className={`py-2.5 px-4 w-full rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary text-white ${
            showSpinner ? "disabled" : ""
          } `}
          showSpinner={showSpinner}
          onClick={handleInviteUsers}
        >
          Send Invite
        </SpinnerButton>
      </div>
    </>
  );
}

type NewUserProp = {
  index: number;
  email: string;
  canManage: boolean;
  canEditContent: boolean;
  canViewContent: boolean;
  canUploadContent: boolean;
  handleEmailChange: (index: number, email: string) => void;
  handleRemoveUser: (index: number) => void;
  setCanManage: (index: number, canManage: boolean) => void;
  setCanEditContent: (index: number, canEditContent: boolean) => void;
  setCanViewContent: (index: number, canViewContent: boolean) => void;
  setCanUploadContent: (index: number, canUploadContent: boolean) => void;
  emailIsValid: boolean;
  emailIsUnique: boolean;
};

function NewUser({
  email,
  index,
  canManage,
  canEditContent,
  canViewContent,
  canUploadContent,
  handleEmailChange,
  handleRemoveUser,
  setCanManage,
  setCanEditContent,
  setCanViewContent,
  setCanUploadContent,
  emailIsValid,
  emailIsUnique,
}: NewUserProp) {
  const handleOnEmailChanged = (e: any) => {
    handleEmailChange(index, e.target.value);
  };

  const handleRemoveClick = () => {
    handleRemoveUser(index);
  };

  return (
    <div className="my-4 flex gap-4 text-white items-top">
      <label className="flex flex-col w-2/3">
        <input
          value={email}
          onChange={handleOnEmailChanged}
          className="border-page-border bg-white bg-opacity-5 py-2 px-3 my-2 rounded-md border-0 focus:border focus:border-blue-500 invalid:outline-red-500"
          type="text"
        />
        {!emailIsValid && (
          <small className="text-button-bg-destructive-default ">
            Email is invalid, please use a valid email address
          </small>
        )}
        {!emailIsUnique && (
          <small className="text-button-bg-destructive-default ">
            User already exists
          </small>
        )}
      </label>
      <div className="flex flex-col items-start w-1/3">
        <label className="flex items-center gap-2 px-1 py-1">
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage}
            onChange={(e) => setCanManage(index, e.target.checked)}
          />
          <span>Can manage</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage ? "text-gray-400" : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage || canEditContent}
            onChange={(e) => setCanEditContent(index, e.target.checked)}
            disabled={canManage}
          />
          <span>Can edit</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage || canEditContent || canUploadContent
              ? "text-gray-400"
              : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={
              canManage || canEditContent || canUploadContent || canViewContent
            }
            onChange={(e) => setCanViewContent(index, e.target.checked)}
            disabled={canManage || canEditContent || canUploadContent}
          />
          <span>Can view</span>
        </label>
        <label
          className={`flex items-center gap-2 px-1 py-1 ${
            canManage ? "text-gray-400" : ""
          }`}
        >
          <input
            className="border border-gray-400 focus:shadow-none bg-input-border w-4 h-4"
            type="checkbox"
            checked={canManage || canUploadContent}
            onChange={(e) => setCanUploadContent(index, e.target.checked)}
            disabled={canManage}
          />
          <span>Can upload</span>
        </label>
      </div>
      <Trash2
        onClick={handleRemoveClick}
        className={`cursor-pointer text-button-bg-destructive-default mt-4 ${
          index !== 0 ? "visible" : "invisible"
        }`}
      />
    </div>
  );
}
