import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../../helpers";
import { GetNewInstance } from "../../helpers";
import { UsersClient } from "../../services";

export function RequireAuth({ children }: any) {
  const navigate = useNavigate();
  const loggedInUser = Store.getLoggedInUser();
  const token = Store.getToken();

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }

    if (!loggedInUser) {
      const client = new UsersClient(undefined, GetNewInstance());
      client
        .getLoggedInUserInfo()
        .then((x) => {
          if (x?.data) {
            const userInfo = x?.data;
            Store.setLoggedInUser(userInfo);
            if (!userInfo.isAdminRole && userInfo.hasSingleCustomer) {
              Store.setCurrentCustomer({
                id: userInfo.customerId,
                name: userInfo.companyName ?? "",
                timeZoneName: userInfo.timeZoneInfo?.timeZoneName ?? "",
                timeZoneIANA: userInfo.timeZoneInfo?.timeZoneIANA ?? "",
                timeZoneOffset: userInfo.timeZoneInfo?.timeZoneOffset ?? "",
                totalFileSizedUsed: userInfo.totalFileSizedUsed ?? 0,
                storageLimit: userInfo.storageLimit,
                allowedCanvasesCount: userInfo.allowedCanvasesCount,
                canvasesCount: userInfo.canvasesCount,
              });
            }
          }
        })
        .catch((x) => {
          console.log(x);
        });
    }
  }, [loggedInUser, navigate, token]);

  return children;
}
